import { toast } from "react-toastify";
import { API } from ".";

export const fetchChats = async (
  limit: number = 25,
  offset: number = 0,
  search: string = ""
) => {
  try {
    const { data } = await API.get("/chats", {
      params: {
        limit: limit,
        offset: offset,
        search: search,
      },
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const fetchChat = async (chat_id: number, userFrom: string) => {
  try {
    const { data } = await API.get(`/chats/${userFrom}/${chat_id}`);
    return { data, chat_id };
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const sendMessage = async (
  chat_id: number,
  message: string,
  userFrom: string
) => {
  try {
    const { data } = await API.post("/send_message", {
      chat_id: chat_id,
      message: message,
      username: userFrom,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const pinChat = async (chat_id: number, username: string) => {
  try {
    const { data } = await API.post("/pin_dialog", {
      chat_id: chat_id,
      username: username,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const unpinChat = async (chat_id: number, username: string) => {
  try {
    const { data } = await API.post("/unpin_dialog", {
      chat_id: chat_id,
      username: username,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const addToSpam = async (chat_id: number, username: string) => {
  try {
    const { data } = await API.post("/add_spam", {
      chat_id: chat_id,
      username: username,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const getSpamChats = async (search: string = "") => {
  try {
    const { data } = await API.get("/chats/spam", {
      params: {
        search: search,
      },
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const deleteFromSpam = async (chat_id: number) => {
  try {
    const { data } = await API.post("/delete_spam", { chat_id: chat_id });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const markAsRead = async (chat_id: number, username: string) => {
  try {
    const { data } = await API.post("/mark_as_read", {
      chat_id: chat_id,
      username: username,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};

export const markAsDealt = async (chat_id: number) => {
  try {
    const { data } = await API.post("/made_deal", {
      chat_id: chat_id,
    });
    return data;
  } catch (error) {
    toast.error("Сервис временно недоступен");
    throw error;
  }
};
