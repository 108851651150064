import jwtDecode from "jwt-decode";
import { API } from ".";

export const login = async (
  login: string,
  password: string
  // project: string
) => {
  const { data } = await API.post("/login", { login, password });
  localStorage.setItem("token", data.token);
  return jwtDecode(data.token);
};

export const checkAuth = async () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return null;
  }
  try {
    const response = await API.get("/check");
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }
    return null;
  }
};

export const addUser = async (
  login: string,
  password: string,
  role: string
) => {
  const { data } = await API.post("/register", { login, password, role });
  return data;
};

export const fetchUsers = async () => {
  const { data } = await API.get("/users");
  return data;
};

export const checkRole = async () => {
  const { data } = await API.get("/role");
  return data;
};

export const deleteUser = async (id: number) => {
  const { data } = await API.delete(`users/${id}`);
  return data;
};
