import { FC } from "react";
import { ISVG } from "./ISVG";

export const SearchSVG: FC<ISVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.9998 1.80693C5.13381 1.80693 1.9998 4.94094 1.9998 8.80693C1.9998 12.6729 5.13381 15.8069 8.9998 15.8069C12.8658 15.8069 15.9998 12.6729 15.9998 8.80693C15.9998 4.94094 12.8658 1.80693 8.9998 1.80693ZM0.799805 8.80693C0.799805 4.2782 4.47107 0.606934 8.9998 0.606934C13.5285 0.606934 17.1998 4.2782 17.1998 8.80693C17.1998 10.8553 16.4487 12.7283 15.2069 14.1655L17.8241 16.7827C18.0584 17.017 18.0584 17.3969 17.8241 17.6312C17.5898 17.8655 17.2099 17.8655 16.9755 17.6312L14.3584 15.014C12.9212 16.2558 11.0482 17.0069 8.9998 17.0069C4.47107 17.0069 0.799805 13.3357 0.799805 8.80693Z"
        fill="#518BCC"
      />
    </svg>
  );
};
