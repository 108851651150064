import { IChat } from "src/types/IChat";
import { IDialog } from "src/types/IDialog";
import create, { GetState, SetState } from "zustand";

interface DialogStore {
  dialogs: IDialog[];
  dialogsPage: number;
  setDialogsPage: (page: number) => void;
  setDialogs: (value: IDialog[], full?: boolean) => void;
  updateDialogs: (dialog: IDialog) => void;
  pizdec: boolean;
  setPizdec: (value: boolean) => void;
  errorMessage: string | null;
  setErrorMessage: (value: string | null) => void;
  isLoadChat: boolean;
  setIsLoadChat: (value: boolean) => void;
}

const useDialogStore = create<DialogStore>(
  (set: SetState<DialogStore>, get: GetState<DialogStore>) => ({
    dialogs: [],
    dialogsPage: 1,
    setDialogsPage: (page: number) => set({ dialogsPage: page }),
    setDialogs: (dialogs: IDialog[], full: boolean = false) => {
      full
        ? set(() => ({ dialogs: dialogs }))
        : set((state) => ({ dialogs: [...state.dialogs, ...dialogs] }));
    },
    updateDialogs: (dialog: IDialog) => {
      let filteredDialogs: IDialog[];
      if (dialog.pinned) {
        filteredDialogs = get().dialogs;
        const index = filteredDialogs.findIndex(
          (item) =>
            item.id === dialog.id &&
            item.user_name_from === dialog.user_name_from
        );
        filteredDialogs.splice(index, 1, dialog);

        set(() => ({ dialogs: [...filteredDialogs] }));
      } else {
        filteredDialogs = get().dialogs.filter(
          (item) =>
            item.id !== dialog.id ||
            item.user_name_from !== dialog.user_name_from
        );
        set(() => ({ dialogs: [dialog, ...filteredDialogs] }));
      }
    },
    pizdec: false,
    setPizdec: (value: boolean) => set({ pizdec: value }),
    errorMessage: null,
    setErrorMessage: (value: string | null) => set({ errorMessage: value }),
    isLoadChat: true,
    setIsLoadChat: (value: boolean) => set({ isLoadChat: value }),
  })
);

export default useDialogStore;
