import { IChat } from "src/types/IChat"
import create, { GetState, SetState } from "zustand"

interface MarkUsersStore {
  marksUsers: {from: string, to: string}[]
  setMarksUsers: (value: {from: string, to: string}[]) => void
}

const useMarkUsersStore = create<MarkUsersStore>(
  (set: SetState<MarkUsersStore>, get: GetState<MarkUsersStore>) => ({
    marksUsers: [],
    setMarksUsers: (marksUsers: {from: string, to: string}[]) => set({ marksUsers: marksUsers }),
  }),
)

export default useMarkUsersStore
