import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTE } from "src/consts";
import { checkAuth } from "src/http/UserAPI";
import useUserStore from "src/store/userStore";
interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { isAuth, setIsAuth } = useUserStore();

  useEffect(() => {
    const checkIsAuth = async () => {
      const res = await checkAuth();

      if (!res) {
        navigate(AUTH_ROUTE);
        setIsAuth(false);
      } else {
        setIsAuth(true);
      }
    };

    checkIsAuth();
  }, [navigate]);

  return <>{children}</>;
};
