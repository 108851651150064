import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import s from "./Button.module.scss";
import cn from "classnames";

interface Props {
  type?: "submit" | "reset" | "button";
  style?: "primary" | "disabled";
  children: ReactNode;
  onClick?: () => void;
}

const Button: FC<Props> = ({ type, style = "primary", children, onClick }) => {
  return (
    <button className={cn(s.root, s[style])} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

export default Button;
