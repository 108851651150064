import Auth from "./pages/Auth/Auth";
import {
  ACCOUNTS_ROUTE,
  ADMIN_ROUTE,
  AUTH_ROUTE,
  DIALOGS_ROUTE,
  CONTACTS_ROUTE,
  TG_ACCOUNTS_ROUTE,
  SPAM_ROUTE,
  CHAT_ROUTE,
} from "./consts";
import Users from "./pages/Admin/Users/Users";
import Admin from "./pages/Admin/Admin";
import Dialogs from "./pages/Dialogs/Dialogs";

export const publicRoutes = [
  {
    path: AUTH_ROUTE,
    Component: Auth,
  },
];

export const privateRoutes = [
  {
    path: DIALOGS_ROUTE,
    Component: Dialogs,
  },
  {
    path: SPAM_ROUTE,
    Component: Dialogs,
  },
];

export const adminRoutes = [
  ...privateRoutes,
  {
    path: ADMIN_ROUTE,
    Component: Admin,
  },
];
