import React, { useState, useEffect } from "react";
import s from "./Accounts.module.scss";
import Modal from "src/UI/Modal/Modal";
import {
  errorNotify,
  successNotify,
} from "src/components/provider/ToastProvider";
import { fetchUsers } from "src/http/UserAPI";
import {
  addAccount,
  addAccountCloud1,
  addAccountCloud2,
  addAccountCloud3,
  addAccountCode1,
  addAccountCode2,
  addAccountCode3,
  addAccountSeccond,
  addAccountThird,
  changeStatusAccount,
  deleteAccount,
  fetchAccounts,
} from "src/http/AccountAPI";
import { validatePhone } from "src/utils/utils";

const Accounts = () => {
  const [accounts, setAccounts] = useState<any>([]);
  const [filtersAccounts, setFiltersAccounts] = useState<any>([]);
  const [search, setSearch] = useState("");

  const fetchAccountsData = async () => {
    try {
      const data = await fetchAccounts();

      setAccounts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAccountsData();
  }, []);

  useEffect(() => {}, [search]);

  const [account, setAccount] = useState({
    phone: "",
    api_id: "",
    api_hash: "",
    code: "",
    cloud: "",
  });

  const handleChangeTG = (value: string, name: string) => {
    setAccount({
      ...account,
      [name]: value,
    });
  };

  const [isAddTGModalActive, setIsAddTGModalActive] = useState(false);
  const [page, setPage] = useState("");
  const [hash, setHash] = useState("");

  const addTGAccount = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccount(
        account.phone,
        account.api_id,
        account.api_hash
      );
      setHash(data.hash);
      setPage(data.step);
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const addTGAccountCode1 = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccountCode1(account.phone, account.code, hash);
      setAccount({
        ...account,
        code: "",
      });
      if (data.step === "second") {
        const data2 = await addAccountSeccond(account.phone);
        setHash(data2.hash);
        setPage(data2.step);
      } else {
        setPage(data.step);
      }
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const addTGAccountCode2 = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccountCode2(account.phone, account.code, hash);
      setAccount({
        ...account,
        code: "",
      });
      if (data.step === "third") {
        const data2 = await addAccountThird(account.phone);
        setHash(data2.hash);
        setPage(data2.step);
      } else {
        setPage(data.step);
      }
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const addTGAccountCode3 = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccountCode3(account.phone, account.code, hash);
      if (data.step === "finally") {
        setPage("");
        setAccount({
          phone: "",
          api_id: "",
          api_hash: "",
          code: "",
          cloud: "",
        });
        setIsAddTGModalActive(false);
        successNotify("Пользователь добавлен");
      } else {
        if (data.step === "pass_third") {
          const data2 = await addAccountCloud3(account.phone, account.cloud);
          if (data2.step === "finally") {
            setPage("");
            setAccount({
              phone: "",
              api_id: "",
              api_hash: "",
              code: "",
              cloud: "",
            });
            setIsAddTGModalActive(false);
            successNotify("Пользователь добавлен");
          }
        } else {
          setPage(data.step);
        }
      }
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const addTGAccountCloud1 = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccountCloud1(account.phone, account.cloud);
      if (data.step === "second") {
        const data2 = await addAccountSeccond(account.phone);
        setHash(data2.hash);
        setAccount({
          ...account,
          code: "",
        });
        setPage(data2.step);
      } else {
        setPage(data.step);
      }
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const addTGAccountCloud2 = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addAccountCloud2(account.phone, account.cloud);
      if (data.step === "third") {
        const data2 = await addAccountThird(account.phone);
        setHash(data2.hash);
        setAccount({
          ...account,
          code: "",
        });
        setPage(data2.step);
      } else {
        setPage(data.step);
      }
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const deleteAc = async (phone: string) => {
    await deleteAccount(validatePhone(phone)).then(() => fetchAccountsData());
  };

  const changeStatusAc = async (phone: string) => {
    await changeStatusAccount(validatePhone(phone)).then(() =>
      fetchAccountsData()
    );
  };

  return (
    <div className={s.users}>
      <div className={s.title}>
        <h1>Список тг аккаунтов</h1>
        <div className={s.buttons}>
          <button
            className={s.addAcc}
            onClick={() => setIsAddTGModalActive(true)}
          >
            Добавить ТГ
          </button>
        </div>
      </div>
      <table className={s.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Аккаунт</th>
            <th>Рассыльщик</th>
            <th>Роль</th>
            <th>Статус</th>
            <th>Удаление</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((user: any) => (
            <tr key={user.username}>
              <td>{user.username}</td>
              <td>{user.name}</td>
              <td>{user.real_name}</td>
              <td>{user.tel}</td>
              <td>
                <button
                  onClick={() => changeStatusAc(user.tel)}
                  className={
                    user.is_active ? s.statusButtonRed : s.statusButtonGreen
                  }
                >
                  {user.is_active ? "Деактивировать" : "Активировать"}
                </button>
              </td>
              <td>
                <button
                  className={s.delButton}
                  onClick={() => deleteAc(user.tel)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isAddTGModalActive && (
        <Modal active={isAddTGModalActive} setActive={setIsAddTGModalActive}>
          {page === "" ? (
            <form onSubmit={addTGAccount} className={s.container}>
              <input
                type="phone"
                placeholder="+79290000000"
                value={account.phone}
                onChange={(e) => handleChangeTG(e.target.value, "phone")}
                id="phone"
              />
              <input
                type="text"
                placeholder="api_id"
                value={account.api_id}
                onChange={(e) => handleChangeTG(e.target.value, "api_id")}
                id="api_id"
              />
              <input
                type="text"
                placeholder="api_hash"
                value={account.api_hash}
                onChange={(e) => handleChangeTG(e.target.value, "api_hash")}
                id="api_hash"
              />

              <button type="submit" className={s.create}>
                {" "}
                Добавить{" "}
              </button>
            </form>
          ) : page == "code" ? (
            <form onSubmit={addTGAccountCode1} className={s.container}>
              <h3>Сообщение отправлено на {account.phone}</h3>
              <input
                type="code"
                placeholder="Код"
                value={account.code}
                onChange={(e) => handleChangeTG(e.target.value, "code")}
                id="code"
              />

              <button type="submit" className={s.create}>
                {" "}
                Отправить{" "}
              </button>
            </form>
          ) : page === "code_second" ? (
            <form onSubmit={addTGAccountCode2} className={s.container}>
              <h3>Сообщение отправлено на {account.phone}</h3>
              <input
                type="code"
                placeholder="Код"
                value={account.code}
                onChange={(e) => handleChangeTG(e.target.value, "code")}
                id="code"
              />

              <button type="submit" className={s.create}>
                {" "}
                Отправить{" "}
              </button>
            </form>
          ) : page === "pass" ? (
            <form onSubmit={addTGAccountCloud2} className={s.container}>
              <h3>Введите облачный пароль от аккаунта</h3>
              <input
                type="cloud"
                placeholder="Облачный пароль"
                value={account.cloud}
                onChange={(e) => handleChangeTG(e.target.value, "cloud")}
                id="cloud"
              />
              <button type="submit" className={s.create}>
                {" "}
                Отправить{" "}
              </button>
            </form>
          ) : page === "pass_second" ? (
            <form onSubmit={addTGAccountCloud1} className={s.container}>
              <h3>Введите облачный пароль от аккаунта</h3>
              <input
                type="cloud"
                placeholder="Облачный пароль"
                value={account.cloud}
                onChange={(e) => handleChangeTG(e.target.value, "cloud")}
                id="cloud"
              />
              <button type="submit" className={s.create}>
                {" "}
                Отправить{" "}
              </button>
            </form>
          ) : (
            <form onSubmit={addTGAccountCode3} className={s.container}>
              <h3>Сообщение повторно отправлено на {account.phone}</h3>
              <input
                type="code"
                placeholder="Код"
                value={account.code}
                onChange={(e) => handleChangeTG(e.target.value, "code")}
                id="code"
              />

              <button type="submit" className={s.create}>
                {" "}
                Отправить{" "}
              </button>
            </form>
          )}
        </Modal>
      )}
    </div>
  );
};

export default Accounts;
