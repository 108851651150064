import React, {
  FormEvent,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from "react";
import s from "./ChatBlock.module.scss";
import useChatStore from "src/store/chatStore";
import {
  fetchChat,
  fetchChats,
  markAsRead,
  sendMessage,
} from "src/http/chatAPI";
import { IChat } from "src/types/IChat";
import dayjs from "dayjs";
import Loader from "../../UI/Loader/Loader";
import DownArrowSVG from "../../assets/svg/DownArrowSVG";
import { BASE_URL, BASE_WSS } from "src/http";
import { io } from "socket.io-client";
import { IDialog, PizdecType } from "src/types/IDialog";
import { Player } from "@lottiefiles/react-lottie-player";
import useDialogStore from "src/store/dialogsStore";
import { SendSVG } from "src/UI/svg/SendSVG";
import { useWindowDimensions } from "src/hooks/useWindowDimension";
import { SearchSVG } from "src/UI/svg/SearchSVG";
import { BackArrowSVG } from "src/UI/svg/BackArrowSVG";
import { CopySVG } from "src/UI/svg/CopySVG";

const ChatBlock = ({
  chat,
  setChat,
}: {
  chat: IChat[];
  setChat: (value: IChat[]) => void;
}) => {
  const {
    chatId,
    username,
    userNickname,
    lastSeen,
    userFrom,
    isLoad,
    setIsLoad,
    isFetch,
    setIsFetch,
  } = useChatStore();
  const { setDialogs, setPizdec, setIsLoadChat, setDialogsPage, dialogsPage } =
    useDialogStore();
  const [send, setSend] = useState<IChat[]>([]);
  const chatRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [targetIds, setTargetIds] = useState<number[]>([]);
  const [targetIndex, setTargetIndex] = useState<number>(-1);
  const size = useWindowDimensions();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isMobileSearch, setIsMobileSearch] = useState(false);

  useEffect(() => {
    if (
      !textareaRef.current ||
      !chatRef.current ||
      textareaRef.current.scrollHeight > 100
    )
      return;

    textareaRef.current.style.height = "0px";

    const scrollHeight = textareaRef.current.scrollHeight;

    chatRef.current.style.height = `calc(100vh - ${88 + scrollHeight}px)`;
    textareaRef.current.style.height = scrollHeight + "px";
  }, [textareaRef, message]);

  const getChatData = async () => {
    await fetchChat(chatId, userFrom).then(({ data, chat_id }) => {
      if (isFetch && chatId === chat_id) {
        setChat(data.reverse());
        if (
          send.length > 0 &&
          data.length > 0 &&
          data[send.length - 1].id >= send[send.length - 1].id
        ) {
          setSend([]);
          setIsFetch(true);
        }
      }
      setIsLoad(false);
    });
  };

  const onChatsSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const offset = (dialogsPage - 1) * 25;
      const data: IDialog[] | PizdecType = await fetchChats(
        25,
        offset,
        e.target.value
      );

      const dataIsPizdec = (
        checkingData: IDialog[] | PizdecType
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(data)) {
        setPizdec(true);
        return;
      }

      setDialogs(data, true);
      setDialogsPage(1);
      setIsLoadChat(false);
    } catch (e: any) {
      console.error("Error fetching dialogs:", e);
    }
  };

  useEffect(() => {
    setChat([]);
    setSend([]);

    if (chatId) getChatData();
  }, [chatId, userFrom]);

  const submit = async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    try {
      e.preventDefault();
      if (message) {
        const date = new Date();
        setChat([
          ...chat,
          {
            id: chat[chat.length - 1].id + 1,
            text: message,
            sticker: null,
            date: String(date),
            out: true,
          },
        ]);
        setMessage("");
        await sendMessage(chatId, message, userFrom);
        await markAsRead(chatId, userFrom);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searching = () => {
    setTargetIds(
      chat
        .filter((chat: IChat) =>
          chat.text.toLowerCase().includes(search.toLowerCase())
        )
        .map((chat: IChat) => chat.id)
        .reverse()
    );
  };

  useEffect(() => {
    if (search) searching();
  }, [search, chat]);

  useEffect(() => {
    setTargetIndex(-1);
  }, [search, chatId]);

  const scrollTop = () => {
    if (targetIndex + 1 < targetIds.length) {
      setTimeout(
        () => (window.location.href = `#${targetIds[targetIndex + 1]}`)
      );
      setTargetIndex(targetIndex + 1);
    } else {
      window.location.href = `#${targetIds[targetIndex]}`;
    }
  };

  const scrollBottom = () => {
    if (targetIndex - 1 >= -1) {
      setTimeout(
        () => (window.location.href = `#${targetIds[targetIndex + 1]}`)
      );
      setTargetIndex(targetIndex - 1);
    } else {
      window.location.href = `#${targetIds[targetIndex]}`;
    }
  };

  const submitOnEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      submit(event);
    }
  };

  return (
    <div className={s.container}>
      <div ref={chatRef} className={s.block}>
        {chatId !== 0 && (
          <div className={s.top}>
            {size.width < 768 && (
              <a href="/chats">
                <BackArrowSVG />
                <p>Назад</p>
              </a>
            )}
            <div className={s.username}>
              <h2>{username}</h2>
            </div>
            <div>
              {size.width >= 768 || isMobileSearch ? (
                <div className={s.searchModal}>
                  <div>
                    <input
                      placeholder="Поиск"
                      // value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                    />
                    {search && (
                      <span>
                        {targetIndex + 1} из {targetIds.length}
                      </span>
                    )}
                    {search.length > 0 && targetIds.length > 0 && (
                      <>
                        <button className={s.upArrow} onClick={scrollTop}>
                          <DownArrowSVG />
                        </button>
                        <button onClick={scrollBottom}>
                          <DownArrowSVG />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <SearchSVG
                  onClick={() => {
                    console.log(isMobileSearch);
                    setIsMobileSearch(!isMobileSearch);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <div className={s.chat}>
          {isLoad ? (
            <div className={s.loader}>
              <Loader />
            </div>
          ) : (
            <>
              {chat?.map((message: IChat) => (
                <div className={s.messageWrapper}>
                  {message.deleted && !message.out && (
                    <p className={s.deleted}>Удалено</p>
                  )}
                  {message.sticker ? (
                    <div
                      style={{
                        background:
                          targetIds[targetIndex] === message.id
                            ? "#162335"
                            : undefined,
                      }}
                      key={message.id}
                      id={message.id.toString()}
                    >
                      <div
                        className={`${s.message} ${
                          message.out ? s.out : s.in
                        } ${message.deleted ? s.message__deleted : ""}`}
                      >
                        <p>
                          {message.sticker.includes(".json") ? (
                            <Player
                              src={`${BASE_URL}file/${message.sticker}`}
                              className="player"
                              loop
                              autoplay
                            />
                          ) : message.sticker.includes(".webm") ? (
                            <video
                              className={s.webmSticker}
                              id="webmSticker"
                              autoPlay
                              loop
                            >
                              <source
                                src={`${BASE_URL}file/${message.sticker}`}
                                type="video/webm"
                              />
                            </video>
                          ) : (
                            <img src={`${BASE_URL}file/${message.sticker}`} />
                          )}
                        </p>
                        <span>{dayjs(message.date).format("HH:mm")}</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        background:
                          targetIds[targetIndex] === message.id
                            ? "#162335"
                            : undefined,
                      }}
                      key={message.id}
                      id={message.id.toString()}
                    >
                      <div
                        className={`${s.message} ${
                          message.out ? s.out : s.in
                        } ${message.deleted ? s.message__deleted : ""}`}
                      >
                        <p>
                          {message.text ? (
                            message.text
                          ) : (
                            <span className={s.red}>
                              Голосовое сообщение или файл
                            </span>
                          )}
                        </p>
                        <span>{dayjs(message.date).format("HH:mm")}</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {send?.map((message: IChat) => (
                <div
                  style={{
                    background:
                      targetIds[targetIndex] === message.id
                        ? "#162335"
                        : undefined,
                  }}
                  key={String(message.id) + "_send"}
                  id={message.id.toString()}
                >
                  <div className={`${s.message} ${message.out ? s.out : s.in}`}>
                    <p>{message.text ? message.text : "Стикер"}</p>
                    <span>{dayjs(message.date).format("HH:mm")}</span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {chatId !== 0 && (
          <form onSubmit={submit} className={s.input}>
            <textarea
              ref={textareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Введите сообщение"
              onKeyDown={submitOnEnter}
            />
            <button className={s.sendButton} type="submit">
              <SendSVG />
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChatBlock;
