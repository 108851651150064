import React from "react";

function DownArrowSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
    >
      <g clipPath="url(#clip0_35_10)">
        <path
          fill="#fff"
          d="M11.066 3.435l-.517-.518a.327.327 0 00-.476 0l-4.07 4.07-4.068-4.07a.327.327 0 00-.476 0l-.518.518a.327.327 0 000 .476l4.825 4.825a.327.327 0 00.476 0l4.824-4.825a.327.327 0 000-.476z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_35_10">
          <path
            fill="#fff"
            d="M0 0H10.332V10.332H0z"
            transform="translate(.838 .66)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownArrowSVG;