import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { adminRoutes, privateRoutes, publicRoutes } from "./Routes";
import useUserStore from "./store/userStore";
import { useEffect } from "react";
import { AUTH_ROUTE, DIALOGS_ROUTE } from "./consts";
import useDialogStore from "./store/dialogsStore";

const AppRouter = () => {
  const { isAuth, isLogin, role } = useUserStore();
  const navigate = useNavigate();
  const { pizdec } = useDialogStore();

  return (
    <>
      {pizdec ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            gap: 20,
          }}
        >
          <iframe
            src="https://gifer.com/embed/90rL"
            width="480"
            height="536.816"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <h1 style={{ fontSize: 80 }}>Telegram сбоит...</h1>
          <h2 style={{ fontSize: 50 }}>Не обижатесь, мы не виноваты :(</h2>
          <h3 style={{ fontSize: 30 }}>
            Через время всё само пройдет! Приложите подорожник!
          </h3>
        </div>
      ) : (
        <Routes>
          {role === "ADMIN" &&
            adminRoutes.map(({ path, Component, Components }: any) => (
              <Route key={path} path={path} Component={Component}>
                {/* {Components.map(({ path, Component }: any) => (
                  <Route key={path} path={path} Component={Component} />
                ))} */}
              </Route>
            ))}

          {isAuth &&
            privateRoutes.map(({ path, Component, Components }: any) => (
              <Route key={path} path={path} Component={Component}>
                {/* {Components.map(({ path, Component }: any) => (
                  <Route key={path} path={path} Component={Component} />
                ))} */}
              </Route>
            ))}

          {publicRoutes.map(({ path, Component }: any) => (
            <Route key={path} path={path} Component={Component} />
          ))}
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
