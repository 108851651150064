import { FC } from "react";
import { ISVG } from "./ISVG";

export const BackArrowSVG: FC<ISVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="13"
      viewBox="0 0 6 13"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.70054 0.346828C5.94402 0.555524 5.97222 0.922085 5.76352 1.16556L1.44227 6.20703L5.76352 11.2485C5.97222 11.492 5.94402 11.8585 5.70054 12.0672C5.45706 12.2759 5.0905 12.2477 4.88181 12.0043L0.236654 6.58491C0.0502725 6.36746 0.0502725 6.0466 0.236654 5.82915L4.88181 0.409808C5.0905 0.166329 5.45706 0.138132 5.70054 0.346828Z"
        fill="#518BCC"
      />
    </svg>
  );
};
