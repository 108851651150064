export const colorMap: { [key: string]: string } = {
  а: "#FFCCCC",
  б: "#CCCCFF",
  в: "#CCFFCC",
  г: "#FFD699",
  д: "#E6CCFF",
  е: "#D9B38C",
  ж: "#CCFFFA",
  з: "#FFD9EB",
  и: "#D98C8C",
  й: "#8C8CD9",
  к: "#CCE699",
  л: "#CCFFFF",
  м: "#B38FD9",
  н: "#FFB3B3",
  о: "#B3B3FF",
  п: "#99CC99",
  р: "#FFCC99",
  с: "#E6CCFF",
  т: "#CC9966",
  у: "#CCFFFA",
  ф: "#FFCCCC",
  х: "#D98C8C",
  ц: "#8C8CD9",
  ч: "#B3CC99",
  ш: "#CCFFFA",
  щ: "#B38FD9",
  ъ: "#FFCCCC",
  ы: "#CCF5FF",
  ь: "#98FB98",
  э: "#FFCCFA",
  ю: "#FFFACD",
  я: "#B0E0E6",
  a: "#FFCCCC",
  b: "#CCCCFF",
  c: "#CCFFCC",
  d: "#FFD699",
  e: "#E6CCFF",
  f: "#D9B38C",
  g: "#CCFFFA",
  h: "#FFD9EB",
  i: "#D98C8C",
  j: "#8C8CD9",
  k: "#CCE699",
  l: "#CCFFFF",
  m: "#B38FD9",
  n: "#FFB3B3",
  o: "#B3B3FF",
  p: "#99CC99",
  q: "#FFCC99",
  r: "#E6CCFF",
  s: "#CC9966",
  t: "#CCFFFA",
  u: "#FFCCCC",
  v: "#D98C8C",
  w: "#8C8CD9",
  x: "#B3CC99",
  y: "#CCFFFA",
  z: "#B38FD9",
  "0": "#FF6666",
  "1": "#66CC66",
  "2": "#666699",
  "3": "#CCAC00",
  "4": "#9932CC",
  "5": "#FF6666",
  "6": "#CCFFFA",
  "7": "#FF66B2",
  "8": "#FFD699",
  "9": "#B3CC99",
  "!": "#CCCCFF",
  "@": "#CCFFCC",
  "#": "#FF6666",
  $: "#FFD699",
  "%": "#E6CCFF",
  "^": "#CCFFFA",
  "&": "#FF66B2",
  "*": "#CC9966",
  "(": "#CCFFFA",
  ")": "#FFD9EB",
  "-": "#D98C8C",
  _: "#8C8CD9",
  "+": "#B3CC99",
  "=": "#CCFFFA",
  "[": "#B38FD9",
  "]": "#FF6666",
  "{": "#CCCCFF",
  "}": "#CCFFCC",
  ";": "#FFD699",
  ":": "#E6CCFF",
  ",": "#CC9966",
  ".": "#CCFFFA",
  "/": "#FFD9EB",
  "?": "#D98C8C",
  "<": "#8C8CD9",
  ">": "#B3CC99",
};

export const validatePhone = (phone: string) => {
  if (phone[0] === "+") return phone.slice(1);
  return phone;
};
