import { BrowserRouter, useLocation } from "react-router-dom";
import AppRouter from "./AppRouter";
import { useEffect, useLayoutEffect, useState } from "react";
import useDialogStore from "./store/dialogsStore";
import { IDialog, IDialogErrorMessage, PizdecType } from "./types/IDialog";
import { fetchChats } from "./http/chatAPI";
import useUserStore from "./store/userStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./providers/AuthProvider";

const App = () => {
  const {
    setIsLoadChat,
    setDialogs,
    setPizdec,
    dialogsPage,
    setDialogsPage,
    setErrorMessage,
  } = useDialogStore();
  const { isAuth } = useUserStore();
  const limit = 25;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== "visible") {
        localStorage.setItem("fetchDialogsType", "background");
      } else {
        localStorage.setItem("fetchDialogsType", "open");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useLayoutEffect(() => {
    const dialogsElement = document.getElementById("dialogs");
    if (!dialogsElement) return;
    const handleScroll = () => {
      if (
        dialogsElement?.scrollTop &&
        dialogsElement.scrollTop + dialogsElement.clientHeight >=
          dialogsElement.scrollHeight - 1
      ) {
        fetchDialogsPage();
        setDialogsPage(dialogsPage + 1);
      }
    };

    dialogsElement?.addEventListener("scroll", handleScroll);

    return () => {
      dialogsElement?.removeEventListener("scroll", handleScroll);
    };
  });

  const fetchDialogsPage = async () => {
    try {
      const offset = (dialogsPage - 1) * limit;
      const data: IDialog[] | IDialogErrorMessage | PizdecType =
        await fetchChats(limit, offset);
      const dataIsPizdec = (
        checkingData: IDialog[] | PizdecType | IDialogErrorMessage
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(data)) {
        setPizdec(true);
        return;
      }

      const isErrorMessage = (
        checkingData: IDialog[] | IDialogErrorMessage
      ): checkingData is IDialogErrorMessage => {
        return (checkingData as IDialogErrorMessage).message !== undefined;
      };

      if (isErrorMessage(data)) {
        setErrorMessage(data.message);
        return;
      }

      setDialogs(data);
      setIsLoadChat(false);
    } catch (e: any) {
      console.error("Error fetching dialogs:", e);
    }
  };

  useEffect(() => {
    if (!isAuth) return;

    localStorage.setItem("fetchDialogsType", "open");
    fetchDialogsPage();
    setDialogsPage(dialogsPage + 1);
  }, [isAuth]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
