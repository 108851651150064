import React, { FC, InputHTMLAttributes, ReactNode } from "react";
import cn from "classnames";
import s from "./Input.module.scss";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode;
  label?: string;
}

const Input: FC<Props> = ({ icon, label, ...props }) => {
  return (
    <div className={s.root}>
      {icon && <div className={s.icon}>{icon}</div>}
      {props.type === "checkbox" || props.type === "radio" ? (
        <label htmlFor={props.id}>
          <input {...props} />
          {label}
        </label>
      ) : (
        <input className={s.input} {...props} />
      )}
    </div>
  );
};

export default Input;
