import React, { useRef } from "react";
import s from "./AdminNavBar.module.scss";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { DIALOGS_ROUTE } from "src/consts";

interface NavBarProps {
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  links: any;
}

const AdminNavBar: React.FC<NavBarProps> = ({
  links,
  selected,
  setSelected,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.container}>
          <div className={s.list}>
            {links.map((el: any, ind: any) => (
              <div
                onClick={() => setSelected(ind)}
                key={ind}
                className={classNames(s.el, {
                  [s.el_mod]: selected == ind,
                })}
              >
                <p key={el.code}>{el.title}</p>
              </div>
            ))}
          </div>
          <div className={s.exit} onClick={() => navigate(DIALOGS_ROUTE)}>
            Вернуться к чатам
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavBar;
