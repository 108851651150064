import { FC } from "react";
import { ISVG } from "./ISVG";

export const PinSVG: FC<ISVG> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M9.19141 20.8096L5.31867 24.6823"
        stroke="white"
        stroke-width="1.31445"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7009 13.6523C11.4125 13.9403 11.0495 14.1422 10.6527 14.2352C10.2559 14.3283 9.841 14.3088 9.45467 14.179L7.37888 13.4974C6.99254 13.3675 6.57765 13.348 6.18084 13.4411C5.78403 13.5341 5.42104 13.736 5.13269 14.024L4.54404 14.6127C4.33861 14.8181 4.22321 15.0967 4.22321 15.3872C4.22321 15.6778 4.33861 15.9564 4.54404 16.1618L13.8386 25.4564C14.044 25.6618 14.3226 25.7772 14.6132 25.7772C14.9037 25.7772 15.1823 25.6618 15.3877 25.4564L15.9764 24.8677C16.2644 24.5794 16.4663 24.2164 16.5593 23.8196C16.6524 23.4228 16.6329 23.0079 16.503 22.6215L15.8214 20.5457C15.6916 20.1594 15.6721 19.7445 15.7652 19.3477C15.8582 18.9509 16.0601 18.5879 16.3481 18.2995L19.2604 15.3872C19.4659 15.1818 19.7445 15.0664 20.035 15.0664C20.3255 15.0664 20.6041 15.1818 20.8095 15.3872C21.2204 15.7981 21.7776 16.0289 22.3586 16.0289C22.9396 16.0289 23.4969 15.7981 23.9077 15.3872C24.3186 14.9764 24.5494 14.4192 24.5494 13.8382C24.5494 13.2571 24.3186 12.6999 23.9077 12.2891L17.7113 6.09268C17.3005 5.68184 16.7433 5.45103 16.1622 5.45103C15.5812 5.45103 15.024 5.68184 14.6131 6.09268C14.2023 6.50353 13.9715 7.06076 13.9715 7.64178C13.9715 8.2228 14.2023 8.78003 14.6131 9.19087C14.8186 9.39629 14.934 9.67491 14.934 9.96542C14.934 10.2559 14.8186 10.5345 14.6131 10.74L11.7009 13.6523Z"
        stroke="white"
        stroke-width="1.31445"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
