import create, { GetState, SetState } from "zustand"
import { checkAuth, checkRole } from "src/http/UserAPI"

interface UserStore {
  isAuth: boolean
  role: string
  setIsAuth: (value: boolean) => void
  isLogin: () => void
  logout: () => void
}

const useUserStore = create<UserStore>(
  (set: SetState<UserStore>, get: GetState<UserStore>) => ({
    isAuth: false,
    role: "",
    setIsAuth: (value) => set({ isAuth: value }),
    isLogin: () => {
      const token = localStorage.getItem("token");
      if(token) {
        checkAuth().then((data) => {
          if (data.access) {
            set({ isAuth: true })
            set({ role: data.role })
          } else {
            localStorage.removeItem("token");
          }
        })
      }
    },
    logout: () => {
      localStorage.clear()
      set({ isAuth: false})
      set({ role: ""})
    },
  }),
)


export default useUserStore
