import s from "./Modal.module.scss";
import close from "../../assets/close.svg"

const Modal = ({ active, setActive, children }: any) => {
  return (
    <div className={active ? s.active : s.modal}>
      <div className={s.container} onClick={(e) => e.stopPropagation()}>
        <div className={s.closeModal}>
            <img src={close} alt="close" onClick={() => setActive(false)} />  
        </div>
        <div className={s.content}>{children}</div>
      </div>
    </div>
  );
};  

export default Modal;
