import { Socket } from "socket.io-client";
import create, { GetState, SetState } from "zustand";

interface SocketStore {
  dialogsSocket: Socket | null;
  setDialogsSocket: (value: any) => void;
  disconnectDialogsSocket: () => void;
}

const useSocketStore = create<SocketStore>(
  (set: SetState<SocketStore>, get: GetState<SocketStore>) => ({
    dialogsSocket: null,
    setDialogsSocket: (value: any) => set({ dialogsSocket: value }),
    disconnectDialogsSocket: () => {
      get().dialogsSocket?.disconnect();
      set({ dialogsSocket: null });
    },
  })
);

export default useSocketStore;
