import axios from "axios";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { AUTH_ROUTE } from "src/consts";

export const BASE_WSS = "wss://tg.bwg-io.pro";
export const BASE_URL = "https://tg.bwg-io.pro/-api-/";
// export const BASE_URL = "https://tg.bwg-io.pro/-api-/";
// export const BASE_WSS = "wss://tg.bwg-io.pro";

// export const BASE_URL = "https://corsproxy.io/?https://tg.cloud-testing.ru/-api-/"

// export const BASE_URL = "http://localhost:4000"
// export const BASE_WSS = "ws://localhost:4000"
export const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
});

API.interceptors.request.use(
  (config) => {
    if (typeof localStorage !== "undefined") {
      config.headers["Authorization"] =
        localStorage && localStorage?.getItem("token");
    }
    return config;
  },
  (error) => {
    toast.error("Сервис временно недоступен");
    return Promise.reject(error);
  }
);
