import jwtDecode from "jwt-decode";
import { API } from ".";

export const addAccount = async (
  phone: string,
  api_id: string,
  api_hash: string
) => {
  const { data } = await API.post("/add_account", { phone, api_hash, api_id });
  return data;
};

export const addAccountSeccond = async (phone: string) => {
  const { data } = await API.post("/add_account_second", { phone });
  return data;
};

export const addAccountThird = async (phone: string) => {
  const { data } = await API.post("/add_account_third", { phone });
  return data;
};

export const addAccountCode1 = async (
  phone: string,
  code: string,
  phone_code_hash: string
) => {
  const { data } = await API.post("/add_account_code", {
    phone,
    code,
    phone_code_hash,
  });
  return data;
};

export const addAccountCode2 = async (
  phone: string,
  code: string,
  phone_code_hash: string
) => {
  const { data } = await API.post("/add_account_code_second", {
    phone,
    code,
    phone_code_hash,
  });
  return data;
};

export const addAccountCode3 = async (
  phone: string,
  code: string,
  phone_code_hash: string
) => {
  const { data } = await API.post("/add_account_code_third", {
    phone,
    code,
    phone_code_hash,
  });
  return data;
};

export const addAccountCloud1 = async (phone: string, password: string) => {
  const { data } = await API.post("/add_cloud_pass", { phone, password });
  return data;
};

export const addAccountCloud2 = async (phone: string, password: string) => {
  const { data } = await API.post("/add_cloud_pass_second", {
    phone,
    password,
  });
  return data;
};

export const addAccountCloud3 = async (phone: string, password: string) => {
  const { data } = await API.post("/add_cloud_pass_third", {
    phone,
    password,
  });
  return data;
};

export const changeName = async (
  client_nickname: number,
  custom_name: string
) => {
  const { data } = await API.post("/change_name", {
    client_nickname,
    custom_name,
  });
  return data;
};
export const fetchAccounts = async () => {
  const { data } = await API.get("/accounts");
  return data;
};

export const deleteAccount = async (phone: string) => {
  const { data } = await API.delete(`/delete_account?phone=${phone}`);
  return data;
};

export const changeStatusAccount = async (phone: string) => {
  const { data } = await API.get(`/enable_account?phone=${phone}`);
  return data;
};
