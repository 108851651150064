import React, { useState, useEffect } from "react";
import s from "./Users.module.scss";
import Modal from "src/UI/Modal/Modal";
import {
  errorNotify,
  successNotify,
} from "src/components/provider/ToastProvider";
import { addUser, deleteUser, fetchUsers } from "src/http/UserAPI";

const Users = () => {
  const [users, setUsers] = useState<any>([]);

  const fetchUsersData = async () => {
    try {
      const data = await fetchUsers();

      setUsers(data.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const [fields, setFields] = useState({
    login: "",
    password: "",
    role: "",
  });

  const handleChange = (value: string, name: string) => {
    setFields({
      ...fields,
      [name]: value,
    });
  };

  const [modalActive, setModalActive] = useState(false);

  const openModal = () => {
    setModalActive(true);
  };

  const createUser = async (e: any) => {
    e.preventDefault();
    try {
      const data = await addUser(fields.login, fields.password, fields.role);
      setModalActive(false);
      fetchUsersData();
      successNotify("Пользователь создан");
      setFields({
        login: "",
        password: "",
        role: "",
      });
    } catch (error) {
      errorNotify("Ошибка создания");
    }
  };

  const handleDelete = async (id: number, login: string) => {
    try {
      confirm(`Удалить пользователя ${login}?`);

      if (!confirm(`Удалить пользователя ${login}?`)) {
        return;
      }
      await deleteUser(id);
      fetchUsersData();
      successNotify("Пользователь удален");
    } catch (error) {
      errorNotify("Ошибка удаления");
    }
  };

  return (
    <div className={s.users}>
      <div className={s.title}>
        <h1>Список аккаунтов</h1>
        <div className={s.buttons}>
          <button className={s.addAcc} onClick={openModal}>
            Добавить аккаунт
          </button>
        </div>
      </div>
      <table className={s.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Аккаунт</th>
            <th>Роль</th>
            <th>Удаление</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            (user: any) =>
              user.login !== "gangsta" &&
              user.login !== "userok" && (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.login}</td>
                  <td>{user.role_name}</td>
                  <td
                    style={{ color: "#C51320" }}
                    onClick={() => handleDelete(user.id, user.login)}
                  >
                    Удалить
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
      {modalActive && (
        <Modal active={modalActive} setActive={setModalActive}>
          <form onSubmit={createUser} className={s.container}>
            <input
              type="text"
              placeholder="Логин"
              value={fields.login}
              onChange={(e) => handleChange(e.target.value, "login")}
              id="login"
            />
            <input
              type="text"
              placeholder="Пароль"
              value={fields.password}
              onChange={(e) => handleChange(e.target.value, "password")}
              id="password"
            />
            <select
              name="role"
              id="role"
              value={fields.role}
              onChange={(e) => handleChange(e.target.value, "role")}
              defaultValue="default"
            >
              <option value="default" selected>
                Выберите роль
              </option>
              <option value="MANAGER">MANAGER</option>
              <option value="ADMIN">ADMIN</option>
            </select>

            <button type="submit" className={s.create}>
              {" "}
              Создать{" "}
            </button>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Users;
