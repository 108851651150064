import { IChat } from "src/types/IChat";
import { create, GetState, SetState } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ChatStore {
  chatId: number;
  setChatId: (value: number) => void;
  username: string;
  setUsername: (value: string) => void;
  userNickname: string;
  setUserNickname: (value: string) => void;
  lastSeen: string;
  setLastSeen: (value: string) => void;
  userFrom: string;
  setUserFrom: (value: string) => void;
  isLoad: boolean;
  setIsLoad: (value: boolean) => void;
  isFetch: boolean;
  setIsFetch: (value: boolean) => void;
}

const useChatStore = create<ChatStore>()(
  persist(
    (set: SetState<ChatStore>, get: GetState<ChatStore>) => ({
      chatId: 0,
      setChatId: (chatId: number) => set({ chatId: chatId }),
      username: "",
      setUsername: (username: string) => set({ username: username }),
      userNickname: "",
      setUserNickname: (username: string) => set({ userNickname: username }),
      lastSeen: "",
      setLastSeen: (lastSeen: string) => set({ lastSeen: lastSeen }),
      userFrom: "",
      setUserFrom: (userFrom: string) => set({ userFrom: userFrom }),
      isLoad: false as boolean,
      setIsLoad: (isLoad: boolean) => set({ isLoad: isLoad }),
      isFetch: true as boolean,
      setIsFetch: (isFetch: boolean) => set({ isFetch: isFetch }),
    }),
    { name: "chat-storage", storage: createJSONStorage(() => sessionStorage) }
  )
);

export default useChatStore;
