import { FC } from "react";
import { ISVG } from "./ISVG";

export const PencilSVG: FC<ISVG> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7566 5.62145C19.5852 3.79285 22.55 3.79285 24.3786 5.62145C26.2072 7.45005 26.2072 10.4148 24.3786 12.2434L14.8933 21.7287C14.3514 22.2706 14.0323 22.5897 13.6774 22.8665C13.2592 23.1927 12.8066 23.4725 12.3277 23.7007C11.9214 23.8943 11.4933 24.037 10.7662 24.2793L7.43511 25.3897L6.63303 25.6571C5.98247 25.8739 5.26522 25.7046 4.78032 25.2197C4.29542 24.7348 4.1261 24.0175 4.34296 23.367L5.72068 19.2338C5.96303 18.5067 6.10568 18.0787 6.29932 17.6724C6.52755 17.1935 6.80727 16.7409 7.13354 16.3226C7.41035 15.9677 7.72939 15.6487 8.27137 15.1067L17.7566 5.62145ZM7.40051 23.8201L10.242 22.8729C11.0331 22.6092 11.3693 22.4958 11.6823 22.3466C12.0629 22.1653 12.4225 21.943 12.7549 21.6837C13.0284 21.4704 13.2801 21.2205 13.8698 20.6308L21.4393 13.0614C20.6506 12.7832 19.6346 12.2676 18.6835 11.3165C17.7324 10.3654 17.2168 9.34939 16.9387 8.56075L9.36917 16.1302C8.77951 16.7199 8.52959 16.9716 8.3163 17.2451C8.05704 17.5775 7.83476 17.9371 7.65341 18.3177C7.50421 18.6307 7.3908 18.9669 7.12709 19.758L6.17992 22.5995L7.40051 23.8201ZM18.1554 7.34404C18.1896 7.519 18.2474 7.75684 18.3438 8.03487C18.561 8.66083 18.9712 9.48288 19.7442 10.2558C20.5171 11.0288 21.3392 11.439 21.9651 11.6562C22.2432 11.7527 22.481 11.8105 22.656 11.8447L23.3179 11.1827C24.5607 9.93991 24.5607 7.92492 23.3179 6.68211C22.0751 5.4393 20.0601 5.4393 18.8173 6.68211L18.1554 7.34404Z"
        fill="white"
      />
    </svg>
  );
};
