import { FC } from "react";
import { ISVG } from "./ISVG";

export const SendSVG: FC<ISVG> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1757 17.4312C12.9414 17.1969 12.9414 16.817 13.1757 16.5827L16.9515 12.8069L5.6 12.8069C5.26863 12.8069 5 12.5383 5 12.2069C5 11.8756 5.26863 11.6069 5.6 11.6069L16.9515 11.6069L13.1757 7.8312C12.9414 7.59688 12.9414 7.21699 13.1757 6.98267C13.41 6.74836 13.7899 6.74836 14.0243 6.98267L18.8243 11.7827C19.0586 12.017 19.0586 12.3969 18.8243 12.6312L14.0243 17.4312C13.7899 17.6655 13.41 17.6655 13.1757 17.4312Z"
        fill="white"
      />
    </svg>
  );
};
