import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import ChatBlock from "../../components/ChatBlock/ChatBlock";
import DialogsBar from "../../components/DialogsBar/DialogsBar";
import s from "./Dialogs.module.scss";
import useUserStore from "src/store/userStore";
import { io, Socket } from "socket.io-client";
import { BASE_WSS } from "src/http";
import useChatStore from "src/store/chatStore";
import { IChat } from "src/types/IChat";
import { useWindowDimensions } from "src/hooks/useWindowDimension";
import { Outlet, useLocation } from "react-router-dom";

const Dialogs = () => {
  const size = useWindowDimensions();
  const { hash } = useLocation();

  const [chat, setChat] = useState<IChat[]>([]);

  const {
    chatId,
    username,
    lastSeen,
    userFrom,
    isLoad,
    setIsLoad,
    isFetch,
    setIsFetch,
  } = useChatStore();
  const [socket, setSocket] = useState<Socket | null>(null);

  const socketConnectChats = async () => {
    const socket = io(BASE_WSS, {
      extraHeaders: { "ngrok-skip-browser-warning": "69420" },
    });

    socket.on("connect", () => {
      if (chatId !== 0) {
        console.log(`WebSocket connected room ${chatId + userFrom}`);
      }
    });

    if (chatId && userFrom) {
      socket.emit("join", { room: String(chatId) + userFrom });
    }

    socket.on("message", (msg: any) => {
      if (
        msg !== `Joined room: ${chatId + userFrom}` &&
        msg !== `Left room: ${chatId + userFrom}`
      ) {
        if (msg[0].room === `${chatId}${userFrom}`) setChat(msg as any);
      }
    });

    setSocket(socket);
  };

  useEffect(() => {
    if (chatId && userFrom && socket) {
      socket.disconnect();
      if (chatId !== 0) {
        console.log(`WebSocket diconnected`);
      }
    }

    socketConnectChats();
  }, [chatId, userFrom]);

  return (
    <Container>
      <div className={s.row}>
        {size.width >= 768 ? (
          <>
            <div>
              <DialogsBar />
            </div>
            <div id={`${chatId}`}>
              <ChatBlock chat={chat} setChat={setChat} />
            </div>
          </>
        ) : hash ? (
          <ChatBlock chat={chat} setChat={setChat} />
        ) : (
          <DialogsBar />
        )}
      </div>
    </Container>
  );
};

export default Dialogs;
