import React, { useEffect, useState } from "react";
import AdminNavBar from "src/components/AdminNavBar/AdminNavBar";
import { DIALOGS_ROUTE, DashboardLinks } from "src/consts";
import s from "./Admin.module.scss";
import Users from "./Users/Users";
import Accounts from "./Accounts/Accounts";

const Admin = () => {
  const [selected, setSelected] = useState<number>(0);


  return (
    <div className={s.container}>
      <div className={s.navBar}>
      <AdminNavBar
        selected={selected}
        setSelected={setSelected}
        links={DashboardLinks}
      />
      </div>
      

      <div className={s.content}>
        {selected == 0 && <Users />}
        {selected == 1 && <Accounts />}
      </div>
    </div>
  );
};

export default Admin;
