import React, { useEffect, useRef, useState } from "react";
import { IDialog, PizdecType } from "src/types/IDialog";
import s from "./DialogBlock.module.scss";
import dayjs from "dayjs";
import { colorMap } from "src/utils/utils";
import useChatStore from "src/store/chatStore";
import useMarkUsersStore from "src/store/markUsersStore";
import {
  addToSpam,
  fetchChats,
  markAsDealt,
  markAsRead,
  pinChat,
  unpinChat,
} from "src/http/chatAPI";
import useDialogStore from "src/store/dialogsStore";
import PinnedSVG from "src/assets/svg/PinnedSVG";
import useUserStore from "src/store/userStore";
import { toast } from "react-toastify";
import dots from "src/assets/dots.svg";
import { PencilSVG } from "src/UI/svg/PencilSVG";
import { PinSVG } from "src/UI/svg/PinSVG";
import { SpamSVG } from "src/UI/svg/SpamSVG";
import { DealtSVG } from "src/UI/svg/DealtSVG";
import { ReadSVG } from "src/UI/svg/ReadSVG";
import moment from "moment-with-locales-es6";
import { CopySVG } from "src/UI/svg/CopySVG";

const DialogBlock = ({
  dialog,
  setTargetChat,
  targetChat,
}: {
  dialog: IDialog;
  setTargetChat: () => void;
  targetChat: string;
}) => {
  const {
    chatId,
    setChatId,
    setLastSeen,
    setUsername,
    userFrom,
    setUserFrom,
    setIsLoad,
    setUserNickname,
  } = useChatStore();
  moment.locale("ru");

  const { setMarksUsers } = useMarkUsersStore();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const { setDialogs, setPizdec, setIsLoadChat, setDialogsPage } =
    useDialogStore();
  const { role } = useUserStore();
  const contextMenuElement = useRef<HTMLDivElement>(null);
  const contextMenuDots = useRef<HTMLImageElement>(null);

  const fetchDialogsPage = async () => {
    try {
      const data: IDialog[] | PizdecType = await fetchChats();

      const dataIsPizdec = (
        checkingData: IDialog[] | PizdecType
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(data)) {
        setPizdec(true);
        return;
      }

      setDialogs(data, true);
      setDialogsPage(1);
      setIsLoadChat(false);
    } catch (e: any) {
      console.error("Error fetching dialogs:", e);
    }
  };

  const formatDate = (dateString: string) => {
    const dateArr = dateString.split(" ");
    const date = new Date(`${dateArr[0]}T${dateArr[1]}Z`);

    let momentDate = moment(date);
    const currentDate = moment();

    let formattedDate: string;

    if (momentDate.isSame(currentDate, "day")) {
      formattedDate = momentDate.format("HH:mm");
    } else if (momentDate.isSame(currentDate, "year")) {
      formattedDate = momentDate.format("DD.MM");
    } else {
      formattedDate = momentDate.format("DD.MM.YY");
    }

    return formattedDate;
  };

  const select = () => {
    if (chatId !== dialog.id || userFrom !== dialog.user_name_from) {
      console.log(dialog);
      setIsLoad(true);
      setLastSeen(dialog.last_seen);
      setUserNickname(dialog.name);
      setUsername(dialog.title);
      setUserFrom(dialog.user_name_from);
      setChatId(dialog.id);
    }
  };

  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const getColor = () => {
    if (dialog.title[0]) {
      if (colorMap[dialog.title[0].toLowerCase()]) {
        return colorMap[dialog?.title[0].toLowerCase()];
      } else {
        return "#B38FD9";
      }
    }
  };

  const pinnedChat = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();
      if (dialog.pinned) {
        await unpinChat(dialog.id, dialog.user_name_from);
      } else {
        await pinChat(dialog.id, dialog.user_name_from);
      }

      fetchDialogsPage();
      toast.success("Чат закреплен");
    } catch (error) {
      console.error("error:", error);
      toast.error("Ошибка");
    } finally {
      setIsContextMenuOpen(false);
    }
  };

  const handeAddToSpam = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();
      await addToSpam(dialog.id, dialog.user_name_from);
      fetchDialogsPage();
      toast.success("Чат добавлен в спам");
    } catch (error) {
      console.error("error:", error);
      toast.error("Ошибка");
    } finally {
      setIsContextMenuOpen(false);
    }
  };

  const handeRead = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();
      await markAsRead(dialog.id, dialog.user_name_from);
      fetchDialogsPage();
      toast.success("Прочитано");
    } catch (error) {
      console.error("error:", error);
      toast.error("Ошибка");
    } finally {
      setIsContextMenuOpen(false);
    }
  };

  const handeDeal = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.stopPropagation();
      await markAsDealt(dialog.id);
      fetchDialogsPage();
      toast.success("Сделка помечена как завершенная");
    } catch (error) {
      console.error("error:", error);
      toast.error("Ошибка");
    } finally {
      setIsContextMenuOpen(false);
    }
  };

  const handleSetTargetChat = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      setTargetChat();
      fetchDialogsPage();
      toast.success("Имя изменено");
    } catch (error) {
      console.error("error:", error);
      toast.error("Ошибка");
    } finally {
      setIsContextMenuOpen(false);
    }
  };

  const handleCopyUsername = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(dialog.name || dialog.title);
    toast.success("Имя скопировано в буфер обмена");
  };

  useEffect(() => {
    if (!targetChat) setIsContextMenuOpen(false);
  }, [targetChat]);

  return (
    <a
      href={chatId ? `#${chatId}` : "#"}
      onClick={select}
      className={`${s.block} ${
        chatId === dialog.id && userFrom === dialog.user_name_from
          ? s.activeChat
          : ""
      }`}
    >
      <div style={{ backgroundColor: getColor() }} className={s.image}>
        <span>{dialog.title[0]}</span>
      </div>
      <div className={s.content}>
        <div className={s.top}>
          <div className={s.title}>
            <div className={s.nickname} onClick={handleCopyUsername}>
              @{dialog.name}
            </div>
            <h4>
              | {dialog.title} | {dialog.account_name}
            </h4>
          </div>
          <div className={s.messageMeta}>
            <div>
              {dialog.pinned && <PinnedSVG />}
              <span>{formatDate(dialog.date)}</span>
            </div>
            <div className={s.dotsWrapper}>
              <img
                src={dots}
                alt="dots"
                style={{ minWidth: 20 }}
                onClick={handleContextMenu}
                // ref={contextMenuDots}
              />
              {isContextMenuOpen && (
                <div className={s.contextMenu} ref={contextMenuElement}>
                  <button onClick={handleSetTargetChat}>
                    <PencilSVG />
                    Изменить имя
                  </button>
                  <button onClick={pinnedChat}>
                    <PinSVG />
                    {dialog.pinned ? "Открепить" : "Закрепить"}
                  </button>
                  <button onClick={handeAddToSpam}>
                    <SpamSVG /> Добавить в спам
                  </button>
                  {role === "ADMIN" && (
                    <button onClick={handeDeal}>
                      <DealtSVG />
                      Сделка совершена
                    </button>
                  )}
                  <button onClick={handeRead}>
                    <ReadSVG />
                    Пометить, как прочитанное
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={s.message}>
          <p>{dialog.message ? dialog.message : "Стикер"}</p>
          {dialog.unread_count > 0 && (
            <span className={s.unread}>{dialog.unread_count}</span>
          )}
        </div>
      </div>
    </a>
  );
};

export default DialogBlock;
