import React from "react";

function PinnedSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M6.12695 14.0801L3.54513 16.6619"
        stroke="#518BCC"
        stroke-width="0.8763"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.79992 9.30854C7.60768 9.50057 7.36569 9.63517 7.10115 9.6972C6.83661 9.75923 6.56002 9.74623 6.30246 9.65967L4.9186 9.20527C4.66104 9.11871 4.38445 9.10571 4.11991 9.16774C3.85537 9.22977 3.61338 9.36437 3.42114 9.55639L3.02871 9.94883C2.89176 10.0858 2.81482 10.2715 2.81482 10.4652C2.81482 10.6589 2.89176 10.8446 3.02871 10.9816L9.22508 17.1779C9.36203 17.3149 9.54777 17.3918 9.74145 17.3918C9.93512 17.3918 10.1209 17.3149 10.2578 17.1779L10.6503 16.7855C10.8423 16.5933 10.9769 16.3513 11.0389 16.0867C11.1009 15.8222 11.0879 15.5456 11.0014 15.288L10.547 13.9042C10.4604 13.6466 10.4474 13.37 10.5094 13.1055C10.5715 12.841 10.7061 12.599 10.8981 12.4067L12.8396 10.4652C12.9766 10.3282 13.1623 10.2513 13.356 10.2513C13.5497 10.2513 13.7354 10.3282 13.8724 10.4652C14.1463 10.7391 14.5177 10.893 14.9051 10.893C15.2924 10.893 15.6639 10.7391 15.9378 10.4652C16.2117 10.1913 16.3656 9.81982 16.3656 9.43247C16.3656 9.04512 16.2117 8.67364 15.9378 8.39974L11.8069 4.26882C11.533 3.99492 11.1615 3.84105 10.7742 3.84105C10.3868 3.84105 10.0153 3.99492 9.74145 4.26882C9.46755 4.54272 9.31368 4.9142 9.31368 5.30155C9.31368 5.6889 9.46755 6.06038 9.74145 6.33428C9.8784 6.47123 9.95533 6.65697 9.95533 6.85064C9.95533 7.04432 9.8784 7.23006 9.74145 7.36701L7.79992 9.30854Z"
        stroke="#518BCC"
        stroke-width="0.8763"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PinnedSVG;
