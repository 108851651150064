import React, { useEffect, useRef, useState, MouseEvent } from "react";
import {
  deleteFromSpam,
  fetchChats,
  getSpamChats,
  pinChat,
  unpinChat,
} from "src/http/chatAPI";
import { IDialog, PizdecType } from "src/types/IDialog";
import DialogBlock from "../DialogBlock/DialogBlock";
import s from "./DialogsBar.module.scss";
import useDialogStore from "src/store/dialogsStore";
import Loader from "../../UI/Loader/Loader";
import cn from "classnames";
import MenuList from "../Menu/MenuList";
import useMarkUsersStore from "src/store/markUsersStore";
import Modal from "src/UI/Modal/Modal";
import { changeName } from "src/http/AccountAPI";
import { BASE_WSS } from "src/http";
import { io, Socket } from "socket.io-client";
import useUserStore from "src/store/userStore";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import useSocketStore from "src/store/socketStore";
import debounce from "lodash.debounce";

const DialogsBar = () => {
  const {
    dialogs,
    setDialogs,
    isLoadChat,
    updateDialogs,
    setIsLoadChat,
    setPizdec,
    dialogsPage,
    setDialogsPage,
    errorMessage,
    setErrorMessage,
  } = useDialogStore();
  const [sort, setSort] = useState<string>("");
  const [filteredDialogs, setFilteredDialogs] = useState<IDialog[]>([]);
  const [pinned, setPinned] = useState([]);
  const [targetChat, setTargetChat] = useState<string>("");
  const [targetId, setTargetId] = useState<number>(0);
  const { isAuth } = useUserStore();
  const [socket, setSocket] = useState<Socket | null>(null);
  const newMessageAudio = new Audio("iphone.mp3");
  const location = useLocation();
  const [spamChats, setSpamChats] = useState<any>([]);
  let currentSocket: Socket | null = null;
  const limit = 25;
  const dialogsBlock = useRef<HTMLDivElement>(null);

  const { dialogsSocket, setDialogsSocket, disconnectDialogsSocket } =
    useSocketStore();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== "visible") {
        disconnectDialogsSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const socketConnectChats = async () => {
    const SOCKET = io(BASE_WSS, {
      // extraHeaders: { "ngrok-skip-browser-warning": "69420" },
      ackTimeout: 1000,
      retries: 10,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 5000,
    });

    SOCKET.on("connect", () => {
      console.log("WebSocket for dialogs bar connected! SID: ", SOCKET.id);
      if (SOCKET.id) {
        setDialogsSocket(SOCKET);
      }

      SOCKET.emit("join", { room: "chats" });
    });

    SOCKET.on("message", (msg: IDialog | PizdecType) => {
      const dataIsPizdec = (
        checkingData: IDialog | PizdecType
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(msg)) {
        setPizdec(true);
        return;
      }

      if (errorMessage) setErrorMessage(null);

      if (msg.unread_count) {
        newMessageAudio.play();
      }

      updateDialogs(msg);
    });

    SOCKET.on("disconnect", (reason: Socket.DisconnectReason) => {
      console.log("WebSocket for dialogs bar disconnected!");
      // toast.error("Сервис временно недоступен");
      if (isAuth && localStorage.getItem("token") !== null) {
        SOCKET.connect();
      }
    });
  };

  const fetchDialogsPage = async () => {
    try {
      const offset = (dialogsPage - 1) * limit;
      const data: IDialog[] | PizdecType = await fetchChats(limit, offset);

      const dataIsPizdec = (
        checkingData: IDialog[] | PizdecType
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(data)) {
        setPizdec(true);
        return;
      }

      setDialogs(data, true);
      setDialogsPage(1);
      setIsLoadChat(false);
    } catch (e: any) {
      console.error("Error fetching dialogs:", e);
    }
  };

  const fetchSpamChats = async (search: string = "") => {
    try {
      const data = await getSpamChats(search);
      setSpamChats(data);
      fetchDialogsPage();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.pathname === "/spam") {
      fetchSpamChats();
    }
  }, [location.pathname]);

  const returnChatFromSpam = (chatId: number) => {
    deleteFromSpam(chatId);
    setTimeout(() => {
      fetchSpamChats();
    }, 500);
  };

  const onChatsSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const offset = (dialogsPage - 1) * 25;
      const data: IDialog[] | PizdecType = await fetchChats(
        25,
        offset,
        e.target.value
      );

      const dataIsPizdec = (
        checkingData: IDialog[] | PizdecType
      ): checkingData is PizdecType => {
        return (checkingData as PizdecType).status !== undefined;
      };

      if (dataIsPizdec(data)) {
        setPizdec(true);
        return;
      }

      setDialogs(data, true);
      setDialogsPage(1);
      setIsLoadChat(false);
    } catch (e: any) {
      console.error("Error fetching dialogs:", e);
    }
  };

  const onSpamSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    fetchSpamChats(e.target.value);
  };

  const handleSearch = debounce(
    location.pathname === "/spam" ? onSpamSearch : onChatsSearch,
    1000
  );

  useEffect(() => {
    if (isAuth) {
      socketConnectChats();
    }

    return () => {
      if (dialogsSocket) {
        disconnectDialogsSocket();
        console.log("WebSocket disconnected!");
      }
    };
  }, []);

  useEffect(() => {
    const filtered = dialogs.filter(
      (dialog) =>
        dialog.title.toLowerCase().includes(sort.toLowerCase()) ||
        dialog.user_name_from.toLowerCase().includes(sort.toLowerCase())
    );
    setFilteredDialogs(filtered);
  }, [sort, dialogs]);

  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const [newName, setNewName] = useState("");

  const updateName = async (e: any) => {
    e.preventDefault();
    try {
      if (newName) {
        await changeName(targetId, newName).then(() => {
          setNewName("");
          setTargetChat("");
          setTargetId(0);
        });
      }
    } catch (error) {
      console.error("Error update chat name:", error);
    }
  };

  useEffect(() => {
    if (!dialogsBlock.current) return;

    const handleScroll = () => {
      dialogsBlock.current?.classList.add(s.dialogs__scroll);
    };

    const handleScrollEnd = () => {
      dialogsBlock.current?.classList.remove(s.dialogs__scroll);
    };

    dialogsBlock.current.addEventListener("scroll", handleScroll);
    dialogsBlock.current.addEventListener("scrollend", handleScrollEnd);

    return () => {
      dialogsBlock.current?.removeEventListener("scroll", handleScroll);
      dialogsBlock.current?.removeEventListener("scrollend", handleScrollEnd);
    };
  }, [dialogsBlock]);

  return (
    <>
      <div className={s.container}>
        <div className={s.search}>
          <div
            onClick={() => setMenuOpen((prev) => !prev)}
            className={cn(s.menu, menuOpen && s.menu__open)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          {menuOpen && <MenuList isOpen={menuOpen} onClose={closeMenu} />}
          <input
            type="search"
            placeholder="Поиск"
            // value={sort}
            onChange={handleSearch}
          />
        </div>
        {location.pathname === "/spam" ? (
          <div className={s.spam}>
            <h3>СПАМ</h3>
            <div className={s.spamList}>
              {spamChats?.map((chat: any) => (
                <div className={s.spam__el}>
                  <p>{chat.title}</p>
                  <button onClick={() => returnChatFromSpam(chat.chat_id)}>
                    Вернуть
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={s.dialogs} id="dialogs" ref={dialogsBlock}>
            {isLoadChat ? (
              <div className={s.loader}>
                <Loader />
              </div>
            ) : errorMessage ? (
              <p>{errorMessage}</p>
            ) : (
              <>
                {filteredDialogs
                  .filter((dialog) => dialog.pinned)
                  .map((dialog: IDialog) => (
                    <DialogBlock
                      key={`${dialog.id}-${dialog.date}-${dialog.user_name_from}`}
                      dialog={dialog}
                      setTargetChat={() => {
                        setTargetChat(dialog.name);
                        setTargetId(dialog.id);
                        toast.success("Имя изменено");
                      }}
                      targetChat={targetChat}
                    />
                  ))}
                {filteredDialogs
                  .filter((dialog) => !dialog.pinned)
                  .map((dialog: IDialog) => (
                    <DialogBlock
                      key={`${dialog.id}-${dialog.date}-${dialog.user_name_from}`}
                      dialog={dialog}
                      setTargetChat={() => {
                        setTargetChat(dialog.name);
                        setTargetId(dialog.id);
                        toast.success("Имя изменено");
                      }}
                      targetChat={targetChat}
                    />
                  ))}
              </>
            )}
          </div>
        )}
      </div>
      {targetChat && (
        <Modal active={targetChat} setActive={() => setTargetChat("")}>
          <form onSubmit={updateName} className={s.modal}>
            <input
              type="text"
              placeholder="Новый ник"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              id="newName"
            />

            <button type="submit" className={s.create}>
              {" "}
              Обновить{" "}
            </button>
            <span>Имя пользователя обновится в течение 3 минут</span>
          </form>
        </Modal>
      )}
    </>
  );
};

export default DialogsBar;
