import React from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

interface ToastProviderProps {
  children: React.ReactNode
}

export const errorNotify = (text : string) => {
  toast.error(text, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const successNotify = (text : string) => {
  toast.success(text, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
 


  return (
    
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        // hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={undefined}
        draggable
        pauseOnHover={false}
      />
      {children}
    </>
  )
}

export default ToastProvider
