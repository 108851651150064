import React, { FormEvent, useEffect, useState } from "react";
import s from "./Auth.module.scss";
import useUserStore from "src/store/userStore";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import { checkAuth, checkRole, login } from "src/http/UserAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DIALOGS_ROUTE } from "src/consts";
import loginIco from "../../assets/login.svg";
import passwordIco from "../../assets/pasword.svg";
import {
  errorNotify,
  successNotify,
} from "src/components/provider/ToastProvider";
import useSocketStore from "src/store/socketStore";
import { PasswordSVG } from "src/UI/svg/PasswordSVG";

const Auth = () => {
  const { setIsAuth, isAuth, isLogin } = useUserStore();
  const { disconnectDialogsSocket } = useSocketStore();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const projectOptions = ["ABCEX", "EastChange"];

  const [user, setUser] = useState({
    login: "",
    password: "",
    project: "abcex",
  });

  const handleChange = (value: string, name: string) => {
    setUser({
      ...user,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!isAuth) {
      disconnectDialogsSocket();
    }
  }, [isAuth]);

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      await login(user.login, user.password);
      setIsAuth(true);
      isLogin();
      successNotify("Приветствуем");
      navigate(DIALOGS_ROUTE);
      window.history.forward();
    } catch (error) {
      errorNotify("Ошибка входа");
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate(DIALOGS_ROUTE);
    }
  }, [isAuth]);

  const handlePasswordVisible = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <section className={s.block}>
      <form className={s.form} onSubmit={submit}>
        <h1>Вход</h1>
        <Input
          value={user.login}
          onChange={(e) => handleChange(e.target.value, "login")}
          type="text"
          id="login"
          placeholder="Логин"
        />
        <Input
          value={user.password}
          onChange={(e) => handleChange(e.target.value, "password")}
          type={passwordVisible ? "text" : "password"}
          id="password"
          icon={
            <div onClick={() => setPasswordVisible(!passwordVisible)}>
              <PasswordSVG visible={passwordVisible} />
            </div>
          }
          placeholder="Пароль"
        />
        {/* <div className={s.project}>
          <h2>Проект</h2>
          {projectOptions.map((project) => (
            <Input
              type="radio"
              value={project}
              id={project}
              checked={user.project === project.toLowerCase()}
              label={project}
              onChange={(e) =>
                handleChange(e.target.value.toLocaleLowerCase(), "project")
              }
            />
          ))}
        </div> */}
        <Button type="submit">Войти</Button>
      </form>
    </section>
  );
};

export default Auth;
