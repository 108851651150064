export const AUTH_ROUTE = "/";
export const DIALOGS_ROUTE = "/chats";
export const CHAT_ROUTE = `${DIALOGS_ROUTE}/:id`;
export const SPAM_ROUTE = "/spam";
export const ADMIN_ROUTE = "/admin";

export const ACCOUNTS_ROUTE = "/accounts";
export const TG_ACCOUNTS_ROUTE = "/tg-accounts";
export const CONTACTS_ROUTE = "/contacts";

export const DashboardLinks = [
  {
    title: "Аккаунты",
    link: ACCOUNTS_ROUTE,
  },
  {
    title: "ТГ Аккаунты",
    link: TG_ACCOUNTS_ROUTE,
  },
];
