import React, { useEffect, useRef } from "react";
import styles from "./MenuList.module.scss";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROUTE, AUTH_ROUTE, DIALOGS_ROUTE, SPAM_ROUTE } from "src/consts";
import useUserStore from "src/store/userStore";

const MenuList = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { logout, role } = useUserStore();

  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const LogOut = () => {
    logout();
    navigate(AUTH_ROUTE);
  };

  const onSpamClick = () => {
    onClose();
    navigate(SPAM_ROUTE);
  };

  const onChatsClick = () => {
    onClose();
    navigate(DIALOGS_ROUTE);
  };

  return (
    <div
      className={cn(styles.menuList, isOpen && styles.menuList__open)}
      ref={menuRef}
    >
      <nav className={styles.navLinks}>
        {role === "ADMIN" && (
          <div onClick={() => navigate(ADMIN_ROUTE)}>Админ-панель</div>
        )}
        <div onClick={LogOut}>Выйти из аккаунта</div>
        <div onClick={onChatsClick}>Чаты</div>
        <div onClick={onSpamClick}>Спам</div>
      </nav>
    </div>
  );
};

export default MenuList;
